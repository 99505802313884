import React from "react"
import Layout from "../components/layout"
import Pricing from "../components/Pricing"
import PageHeader from "../components/PageHeader"
import SEO from "../components/seo"

class PricingPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Pricing" />
        <PageHeader title="Pricing" />
        <Pricing />
      </Layout>
    )
  }
}

export default PricingPage
